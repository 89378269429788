<template>
  <EvaluationOfferListBase
    :tender="evaluationStore.tender"
    :offers="evaluationStore.offers"
    :offersEvaluations="evaluationStore.offersEvaluations"
    :additionalHeaders
    :isLoadingOffers="evaluationStore.isLoadingOffers"
    contractSumTooltip="Den samlet tilbudte pris inkl. optioner"
    @fetchOffers="evaluationStore.fetchAndSortOffers(calculator)"
    @updateTab="emit('updateTab', $event)"
  >
    <template #additional-columns="{ offer, isMobile }: { offer: Offer0207, isMobile: boolean }">
      <template v-if="isMobile">
        <li class="flex-item" data-label="Skifteomkostninger" v-if="tender0207.data.useSwitchingCosts">
          <SwitchingCostsDialog
            :offer
            :readonly="
              evaluationStore.tender.state !== TenderStateEnum.Evaluate
                || offer.readyForAwarding
                || hasRequestedDocumentationFromWinningOffer
            "
            @offerChanged="evaluationStore.fetchAndSortOffers(calculator)"
          />
          <br />
          <div class="d-flex">
            {{
              (offer.data.switchingCosts || offer.data.switchingCosts === 0) ?? false !== false
                ? toCurrencyFormatDK2(offer.data.switchingCosts)
                : "Skifteomkostninger er ikke indtastet"
            }}
          </div>
        </li>
        <li class="flex-item" data-label="Evalueringteknisk pris inkl. skifteomkostninger" v-if="tender0207.data.useSwitchingCosts">
          {{ toCurrencyFormatDK2((offer.data.tcoCalculationResult?.tcoPrice ?? 0) + (offer.data.switchingCosts ?? 0)) }}
        </li>
      </template>
      <template v-else>
        <td v-if="tender0207.data.useSwitchingCosts">
          <SwitchingCostsDialog
            :offer
            :readonly="
              evaluationStore.tender.state !== TenderStateEnum.Evaluate
                || offer.readyForAwarding
                || hasRequestedDocumentationFromWinningOffer
            "
            @offerChanged="evaluationStore.fetchAndSortOffers(calculator)"
          />
          <br />
          <div class="d-flex">
            {{
              (offer.data.switchingCosts || offer.data.switchingCosts === 0)
                ? toCurrencyFormatDK2(offer.data.switchingCosts)
                : "Skifteomkostninger er ikke indtastet"
            }}
          </div>
        </td>
        <td v-if="tender0207.data.useSwitchingCosts">
          {{ toCurrencyFormatDK2((offer.data.tcoCalculationResult?.tcoPrice ?? 0) + (offer.data.switchingCosts ?? 0)) }}
        </td>
      </template>
    </template>
    <template #tco></template>
    <template #bpq="{ offer, index }">
      <BpqDocumentationDialog
        :offer
        :conditionalOffers
        :index
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  Offer,
  EvaluationOfferListBase,
  toCurrencyFormatDK2,
  TenderStateEnum,
  useEvaluationStore,
} from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';
import { Offer0207, Tender0207 } from '@/models';
import SwitchingCostsDialog from './SwitchingCostsDialog.vue';
import { getCalculator } from '@/services';

const emit = defineEmits<{
  updateTab: [string],
}>();

const evaluationStore = useEvaluationStore();

const calculator = getCalculator();

const tender0207 = computed(() => evaluationStore.tender as Tender0207);

const additionalHeaders = ref(tender0207.value.data.useSwitchingCosts
  ? [
    { text: 'Skifteomkostninger', sortable: false },
    { text: 'Evalueringteknisk pris inkl. skifteomkostninger', sortable: false },
  ]
  : []);

const conditionalOffers = computed(() => {
  // Get offers to be shown in list of conditional offers
  const ofs = evaluationStore.offers.filter(
    (o) => o.status !== 'Unconditional'
        && !(o.isUnconditional ?? false)
        && !calculator.isScoreBelowConditionalThreshold_BackendCalculation(o)
        && !calculator.isScoreBelowConditionalThreshold_FrontendCalculation(o, evaluationStore.tender),
  );
  console.info('get conditional offers returns: ', ofs);
  if (evaluationStore.tender.awardCriteriaType === 'TCO') {
    return ofs;
  }
  return calculator.sortBPQBestFirst(ofs, evaluationStore.tender, evaluationStore.offersEvaluations);
});

const winningOffer = computed((): Offer | undefined => {
  if (conditionalOffers.value.length === 0) {
    return undefined;
  }
  return conditionalOffers.value[0];
});

const hasRequestedDocumentationFromWinningOffer = computed((): boolean => winningOffer.value?.documentationRequested ?? false);
</script>
